import { CHANGE_LANGUAGE, CHANGE_LOADING } from "../../../types";

const changeLanguage = () => {
	return {
		type: CHANGE_LANGUAGE
	}
};
const toggeleMenuLoading = () => {
	return {
		type: CHANGE_LOADING
	}
};


export const SagaAction = {
	changeLanguage,
	toggeleMenuLoading
};