import * as React from 'react'
import {BrowserRouter, Switch, Route} from 'react-router-dom'
import {Page_Route_list} from "./pageConfig";

type Props = {}

const RoutesList = () => {
	return Page_Route_list.map(ele => {
		return (
			<Route
				key={ele.path}
				path={ele.path}
				// component={() => (
				// <MyRoute title={ele.title} Children={ele.component}/>
				// )}
				component={ele.component}
				exact={ele.exact}/>
		)
	})
};

class Pages extends React.Component <Props, any> {

	constructor (props: Object) {
		super(props);
		this.state = {};
	}

	componentWillMount () {
	}

	componentDidMount () {
	}

	componentWillReceiveProps () {
	}

	shouldComponentUpdate () {
		return true
	}

	componentDidUpdate () {

	}

	render () {
		return (
			<div className='page_box'>
				<BrowserRouter>
					<Switch>
						<RoutesList/>
					</Switch>
				</BrowserRouter>
			</div>
		)
	}
}

export default Pages
