/**@flow*/
import * as React from 'react'
import {withRouter} from 'react-router-dom'

type Props = {
	children?: Element,
	location: Object,
	title: String
}
class MyRoute extends React.Component <Props, any> {

	constructor (props: Object) {
		super(props);
		this.state = {
			children: props.children,
			path: props.path,
			title: props.title,
		};
	}

	componentWillMount () {
	}

	componentDidMount () {
		document.title = this.state.title;
	}

	componentWillUnmount () {
	}

	render () {
		const {children} = this.state;
		return children
	}
}

MyRoute = withRouter(MyRoute);
export {MyRoute}