import * as React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import Pages from './routers';
import './app.less';
import TopMenu from './components/common/topMenu/topMenu';
import LayoutBottom from './components/common/layoutBottom/layoutBottom';
import Slider from './components/common/slider/slider';
import { connect } from 'react-redux'


class App extends React.Component<Props, any> {
	constructor(props: Object) {
		super(props);
		this.state = {
		};
	}
	componentDidMount() {
	}

	render() {
		const { isch } = this.props;
		return (
			<Router>
				<div className='layout-box'>
					<TopMenu></TopMenu>
					<div className='content-box'><Pages /></div>
					<LayoutBottom></LayoutBottom>
					{isch && <Slider></Slider>}
				</div>
			</Router>
		)
	}
}

function mapStateToProps(state) {
	return {
		isch: state.isch
	}
}

export default connect(mapStateToProps)(App)
