import { createReducer } from '../createReducer';
import { CHANGE_LANGUAGE, CHANGE_LOADING } from "../types";
const defaultValue = sessionStorage['isch'] ? sessionStorage['isch'] === 'true' ? true : false : true;

export const isch = createReducer(defaultValue, {
  [CHANGE_LANGUAGE](state) {
    sessionStorage['isch'] = !state;
    return !state
  },
});

export const showLoading = createReducer(true, {
  [CHANGE_LOADING](state) {
    return !!state
  },
});



