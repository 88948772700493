import {
	asyncComponent
} from "./common/asyncComponent";

export const Page_Route_list = [
	{
		title: 'Home',
		path: '/home',
		component: asyncComponent(() => import('./pages/home/home'), {
			title: 'Home'
		}),
		exact: true
	},
	// 企业服务
	{
		title: 'enterprise',
		path: '/enterprise',
		component: asyncComponent(() => import('./pages/serviceDelivery/serviceDelivery'), {
			title: 'enterprise'
		}),
		exact: true
	},
	{
		title: 'recruitment',
		path: '/enterprise/recruitment',
		component: asyncComponent(() => import('./pages/serviceDelivery/recruit/recruit'), {
			title: 'recruitment'
		}),
		exact: true
	},
	{
		title: 'certification',
		path: '/enterprise/certification',
		component: asyncComponent(() => import('./pages/serviceDelivery/certification/certification'), {
			title: 'certification'
		}),
		exact: true
	},
	{
		title: 'branchOffice',
		path: '/enterprise/branchOffice',
		component: asyncComponent(() => import('./pages/serviceDelivery/branchOffice/branchOffice'), {
			title: 'branchOffice'
		}),
		exact: true
	},
	{
		title: 'birdview-entrepreneur-club',
		path: '/enterprise/birdview-entrepreneur-club',
		component: asyncComponent(() => import('./pages/serviceDelivery/club/club'), {
			title: 'birdview-entrepreneur-club'
		}),
		exact: true
	},
	{
		title: 'employment-relations-tips',
		path: '/enterprise/employment-relations-tips',
		component: asyncComponent(() => import('./pages/serviceDelivery/hire/hire'), {
			title: 'employment-relations-tips'
		}),
		exact: true
	},
	// 移民
	{
		title: 'immigration',
		path: '/immigration',
		component: asyncComponent(() => import('./pages/immigration/immigration'), {
			title: 'immigration'
		}),
		exact: true
	},
	{
		title: 'immigrationIntroduce',
		path: '/immigration/introduce',
		component: asyncComponent(() => import('./pages/immigration/introduce/introduce'), {
			title: 'immigrationIntroduce'
		}),
		exact: true
	},
	// 留学
	{
		title: 'education',
		path: '/education',
		component: asyncComponent(() => import('./pages/study/study'), {
			title: 'education'
		}),
		exact: true
	},
	{
		title: 'short-term-study',
		path: '/education/short-term-study',
		component: asyncComponent(() => import('./pages/study/micro/mircro'), {
			title: 'short-term-study'
		}),
		exact: true
	},
	{
		title: 'intermediate',
		path: '/education/intermediate',
		component: asyncComponent(() => import('./pages/study/middle/middle'), {
			title: 'intermediate'
		}),
		exact: true
	},
	{
		title: 'university',
		path: '/education/university',
		component: asyncComponent(() => import('./pages/study/university/university'), {
			title: 'university'
		}),
		exact: true
	},

	{
		title: 'visa',
		path: '/visa',
		component: asyncComponent(() => import('./pages/visa/visa'), {
			title: 'visa'
		}),
		exact: true
	},
	{
		title: 'visaIntroduce',
		path: '/visa/introduce',
		component: asyncComponent(() => import('./pages/visa/visaIntroduce/visaIntroduce'), {
			title: 'visaIntroduce'
		}),
		exact: true
	},
	{
		title: 'case',
		path: '/case',
		component: asyncComponent(() => import('./pages/case/case'), {
			title: 'case'
		}),
		exact: true
	},
	{
		title: 'about-us',
		path: '/about-us',
		component: asyncComponent(() => import('./pages/about/about'), {
			title: 'about-us'
		}),
		exact: true
	},
	{
		title: 'company',
		path: '/about-us/company',
		component: asyncComponent(() => import('./pages/about/companyProfile/companyProfile'), {
			title: 'company'
		}),
		exact: true
	},
	{
		title: 'team-member',
		path: '/about-us/team-member',
		component: asyncComponent(() => import('./pages/about/team/team'), {
			title: 'team-member'
		}),
		exact: true
	},
	{
		title: 'contact-us',
		path: '/about-us/contact-us',
		component: asyncComponent(() => import('./pages/about/contact/contact'), {
			title: 'contact-us'
		}),
		exact: true
	},
	{
		title: 'news',
		path: '/news',
		component: asyncComponent(() => import('./pages/new/new'), {
			title: 'news'
		}),
		exact: true
	},
	{
		title: 'Home',
		path: '/',
		component: asyncComponent(() => import('./pages/home/home'), {
			title: 'Home'
		}),
		exact: true
	},
];