import * as React from 'react';
import './layoutBottom.less';
import { connect } from 'react-redux'

class LayoutBottom extends React.Component<props, any> {
  constructor(props: Object) {
    super(props);
    this.state = {}

  }
  render() {
    const { isch } = this.props;
    return (
      <div className="layout-bottom-box">
        <div className='sub-title'>
          <p>Company qualification</p>
          {isch ? <p>博德威公司资质</p> : null}
        </div>
        <div className='qualifications' onClick={(event) => {
          event.persist();
          window.location.href = event.target.dataset.url;
        }}>
          <div className="bg-color"></div>
          <img data-url='https://iaa.ewr.govt.nz/PublicRegister/View.aspx?Firstname=ryan&LastName=shen&search=1&p=1&adviserNumber=202000986' src={require('../../../assets/images/layout-bottom/qualification_2.png')} alt="" />
          {/*<img data-url='https://iaa.ewr.govt.nz/PublicRegister/View.aspx?Firstname=ivan&search=1&p=1&adviserNumber=201801104' src={require('../../../assets/images/layout-bottom/qualification_2.png')} alt="" />*/}
          {/*<img data-url='https://iaa.ewr.govt.nz/PublicRegister/View.aspx?Firstname=crystal&LastName=li&search=1&p=1&adviserNumber=201900893' src={require('../../../assets/images/layout-bottom/qualification_2.png')} alt="" />*/}
          <img data-url='https://iaa.ewr.govt.nz/PublicRegister/View.aspx?Firstname=janelle&search=1&p=1&adviserNumber=201701112' src={require('../../../assets/images/layout-bottom/qualification_2.png')} alt="" />
          <img data-url='https://iaa.ewr.govt.nz/PublicRegister/View.aspx?Firstname=kevin&search=1&p=1&adviserNumber=201503629' src={require('../../../assets/images/layout-bottom/qualification_2.png')} alt="" />
          <img data-url='http://www.birdview.co.nz/nazmi-cn' src={require('../../../assets/images/layout-bottom/qualification_3.png')} alt="" />
          <img data-url='http://www.birdview.co.nz/bay-of-plenty-cn' src={require('../../../assets/images/layout-bottom/qualification_4.png')} alt="" />
          <img data-url='http://www.birdview.co.nz/pire-cn' src={require('../../../assets/images/layout-bottom/qualification_5.png')} alt="" />
        </div>
        {
          isch ? <div className='infor'>
            <div className='infor-time'>
              <img className='infor-title' src={require('../../../assets/images/layout-bottom/title_time.png')} alt="" />
              <img className='infor-icon' src={require('../../../assets/images/layout-bottom/icon_time.png')} alt="" />
              <p>周一至周五：<span>9am - 6pm</span></p>
              <img className='infor-icon' src={require('../../../assets/images/layout-bottom/icon_add.png')} alt="" />
              <p>周六至周日：<span>预约</span></p>
              <img className='infor-icon' src={require('../../../assets/images/layout-bottom/icon_close.png')} alt="" />
              <p>公共假期：<span>Closed</span></p>
            </div>
            <div className='infor-connection'>
              <img className='infor-title' src={require('../../../assets/images/layout-bottom/title_connection.png')} alt="" />
              <img className='infor-icon' src={require('../../../assets/images/layout-bottom/icon_address.png')} alt="" />
              <p>新西兰地址</p>
              <p><span>27 Triton Drive, Rosedale,Auckland 0632</span></p>
              <p className='magin-top'>中国地址</p>
              <p ><span>河南郑州郑东新区雅宝东方国际广场2号楼1502</span></p>
              <img className='infor-icon' src={require('../../../assets/images/layout-bottom/icon_phone.png')} alt="" />
              <p><span>09 476 2568</span></p>
              <p><span>09 476 2567</span></p>
              <img className='infor-icon' src={require('../../../assets/images/layout-bottom/icon_tele.png')} alt="" />
              <p><span>187-0371-9176</span></p>
              <img className='infor-icon' src={require('../../../assets/images/layout-bottom/icon_email.png')} alt="" />
              <p>新西兰：<span>kevin@birdview.co.nz</span></p>
              <p className='magin-top'> 中国：<span>leo@birdview.co.nz</span></p>
            </div>
            <div className='infor-follow'>
              <img className='infor-title' src={require('../../../assets/images/layout-bottom/title_follow.png')} alt="" />
              <p>扫描二维码，立即微信关注博德威</p>
              <div className='btns-follow'>订阅移民猎聘信息或咨询</div>
              <div className='codes'>
                <div>
                  <img className='' src={require('../../../assets/images/layout-bottom/code.png')} alt="" />
                  {/* <p>移民咨询</p> */}
                </div>
                {/* <div>
                  <img className='' src={require('../../../assets/images/layout-bottom/code_study.png')} alt="" />
                  <p>留学咨询</p>
                </div> */}
              </div>
            </div>
          </div> : <div className='infor'>
              <div className='infor-time'>
                <div className="en-infor-title">
                  <span>Hours</span>
                  <span>Working</span>
                </div>
                <img className='infor-icon' src={require('../../../assets/images/layout-bottom/icon_time.png')} alt="" />
                <p>Mon-Fri ：<span>9am - 6pm</span></p>
                <img className='infor-icon' src={require('../../../assets/images/layout-bottom/icon_add.png')} alt="" />
                <p>Sat-Sun ：<span>closed</span></p>
                <img className='infor-icon' src={require('../../../assets/images/layout-bottom/icon_close.png')} alt="" />
                <p>Public Holiday ：<span>closed</span></p>
              </div>
              <div className='infor-connection'>
                <div className="en-infor-title">
                  <span className='us'>Us</span>
                  <span>Contact</span>
                </div>
                <img className='infor-icon' src={require('../../../assets/images/layout-bottom/icon_address.png')} alt="" />
                <p><span>27 Triton Drive, Rosedale,Auckland 0632</span></p>
                <img className='infor-icon' src={require('../../../assets/images/layout-bottom/icon_phone.png')} alt="" />
                <p><span>09 476 2568</span></p>
                <p><span>09 476 2567</span></p>
                <img className='infor-icon' src={require('../../../assets/images/layout-bottom/icon_email.png')} alt="" />
                <p><span>kevin@birdview.co.nz</span></p>
              </div>
              <div className='infor-service'>
                <div className="en-infor-title">
                  <span>Services</span>
                  <span>Our</span>
                </div>
                <p>Immigration </p>
                <p>Recruitment</p>
                <p>Employer Service</p>
                <p>Study in NZ</p>
                <p>Short-term Study</p>
              </div>
            </div>
        }

        <div className='copyright'>
            Copyright © 2019-{new Date().getFullYear()} BIRDVIEW.All rights Reserved.
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    isch: state.isch,
    showLoading: state.showLoading
  }
}

export default connect(mapStateToProps)(LayoutBottom)
