import * as React from 'react';
import './slider.less';


export default class Slider extends React.Component<props, any> {
  constructor(props: Object) {
    super(props);
    this.state = {
      showSlider: false,
      isFirst: true,
      displayNone: false
    }
  }
  toggleSlider = () => {

    this.state.showSlider ? setTimeout(() => {
      this.setState({
        displayNone: false
      })
    }, 600) : this.setState({
      displayNone: true
    })
    this.setState({
      showSlider: !this.state.showSlider,
      isFirst: false
    })
  }
  render() {
    const { showSlider, isFirst, displayNone } = this.state;
    return (
      <div className="slider-box">
        <div className="icon-slider" onClick={this.toggleSlider}>
          <img src={require('../../../assets/images/slider/icon_fixed.png')} alt="" />
        </div>
        {
          displayNone && <div className={showSlider ? 'fadeIn slider' : `slider ${isFirst ? '' : 'fadeOut'}`}>
            <p>请致电博德威</p>
            <div className="tel">
              <div>
                <img src={require('../../../assets/images/slider/icon_phone_1.png')} alt="" />
                <span>09 476 2568</span>
              </div>
              <div>
                <img src={require('../../../assets/images/slider/icon_phone_2.png')} alt="" />
                <span>09 476 2567</span>
              </div>
            </div>
            <p className='title'>免费评估</p>
            <div className="btn-assessment" onClick={() => {
              this.toggleSlider();
              window.location.href = 'https://ezymigrate.co.nz/CustomQuestionnaire/Survey?para=MjY5YTY3YTBhMGEx'
            }}>个人评估</div>
            <div className="btn-assessment" onClick={() => {
              this.toggleSlider();
              window.location.href = 'https://wj.qq.com/s2/3799535/d0d5'
            }}>企业评估</div>
          </div>
        }

      </div>
    )
  }
}
