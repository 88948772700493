import * as React from 'react';
import './topMenu.less'
import { SagaAction } from "./action";
import { connect } from 'react-redux'

type Props = {
  isch?: bollen,
  historty?: Object,
  changeLanguage?: Function,
  toggeleMenuLoading?: Function,

}
class TopMenu extends React.Component<Props, any> {
  constructor(props: Object) {
    super(props);
    this.state = {
      showMeau: false,
      isFirst: true,
      displayNone: false,
    }
  }
  componentDidMount() {
  }
  toggeleMenu = () => {
    this.state.showMeau ? setTimeout(() => {
      this.setState({
        displayNone: false
      })
    }, 600) : this.setState({
      displayNone: true
    })

    this.setState({
      showMeau: !this.state.showMeau,
      isFirst: false
    })
  }
  changeLanguage = () => {
    this.props.changeLanguage();
  }
  render() {
    const { showMeau, isFirst, displayNone } = this.state;
    const { isch } = this.props;
    return (
      <div className='top-menu-box'>
        <div className='top-menu'>
          <img className="btn-menu" onClick={this.toggeleMenu} src={require('../../../assets/images/top-menu/icon_meau.png')} alt="" />
          <img className="logo" src={require('../../../assets/images/top-menu/logo.png')} alt="" />
          <span onClick={this.changeLanguage}> <a href="/home">{this.props.isch ? 'EN' : '中'}</a></span>
        </div>
        {
          displayNone && <div className={`${isch ? '' : 'heig'} menu-box ${showMeau ? 'fadeIn' : isFirst ? '' : 'fadeOut'}`}>
            <div>
              <a href="/" className='title'>
                {isch ? <span>首页</span> : null}
                <span >Home</span>
              </a>
            </div>
            <div>
              <a href="/enterprise" className='title'>
                {isch ? <span>企业服务</span> : null}
                <span>Employer</span>
              </a>
            </div>
            {/*<div>*/}
            {/*  <a href="/enterprise/recruitment">*/}
            {/*    {isch ?*/}
            {/*      <span>*/}
            {/*        <img src={require('../../../assets/images/top-menu/menu_pin.png')} alt="" />*/}
            {/*      人才招聘*/}
            {/*    </span> :*/}
            {/*      <span className='maginLeft'>Recruitment</span>}*/}
            {/*  </a>*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*  <a href="/enterprise/birdview-entrepreneur-club">*/}
            {/*    {isch ? <span>*/}
            {/*      <img src={require('../../../assets/images/top-menu/menu_qi.png')} alt="" />*/}
            {/*      企业俱乐部*/}
            {/*  </span> : null}*/}
            {/*    <span className='maginLeft'>{isch ? null : 'Birdview Entrepreneur club'}</span>*/}
            {/*  </a>*/}
            {/*</div>*/}
            <div>
              <a href="/enterprise/employment-relations-tips">
                {isch ? <span>
                  <img src={require('../../../assets/images/top-menu/menu_gu.png')} alt="" />
                  雇佣关系
              </span> : null}
                <span className='maginLeft'>{isch ? null : 'Employment Relations Tips'}</span>
              </a>
            </div>
            {
              isch ? <div>
                <a href="/enterprise/certification">
                  <span>
                    <img src={require('../../../assets/images/top-menu/menu_yi.png')} alt="" />
                  移民局企业认证
              </span>
                </a>
              </div> : null}


            {isch ? <div>
              <a href="/enterprise/branchOffice">
                <span>
                  <img src={require('../../../assets/images/top-menu/menu_xin.png')} alt="" />
                  新西兰发展公司
              </span>
              </a>
            </div> : null}
            <div>
              <a href="/immigration" className='title'>
                {isch ? <span>移民服务</span> : null}
                <span>Immigration</span>
              </a>
            </div>
            {isch ? <div>
              <a href="/immigration/introduce">
                <span>
                  <img src={require('../../../assets/images/top-menu/menu_yi.png')} alt="" />
                  移民介绍
              </span>
              </a>
            </div> : null}
            <div>
              <a href="/education" className='title'>
                {isch ? <span>留学服务</span> : null}
                <span>Education</span>
              </a>
            </div>
            <div>
              <a href="/education/short-term-study">
                {isch ? <span>
                  <img src={require('../../../assets/images/top-menu/menu_wei.png')} alt="" />
                  微留学
              </span> : <span className='maginLeft'>Short-term study</span>
                }
              </a>
            </div>
            {isch ? <div>
              <a href="/education/intermediate">
                <span>
                  <img src={require('../../../assets/images/top-menu/menu_zhong.png')} alt="" />
                  中小学留学
              </span>
              </a>
            </div> : null}
            <div>
              <a href="/education/university">
                {isch ? <span>
                  <img src={require('../../../assets/images/top-menu/menu_da.png')} alt="" />
                  大学留学
              </span> : <span className='maginLeft'>Study in NZ</span>}

              </a>
            </div>
            {isch ? <div>
              <a href="/visa" className='title'>
                <span>签证服务</span>
                <span>Visa</span>
              </a>
            </div> : null}
            {isch ? <div>
              <a href="/visa/introduce">
                <span>
                  <img src={require('../../../assets/images/top-menu/menu_qian.png')} alt="" />
                  签证介绍
              </span>
              </a>
            </div> : null}

            {isch ? <div>
              <a href="/case" className='title'>
                <span>精彩案例</span>
                <span>Case studies</span>
              </a>
            </div> : null}
            {isch ? <div>
              <a href="/news" className='title'>
                <span>新闻资讯</span>
                <span>News information</span>
              </a>
            </div> : null}
            <div>
              <a href="/about-us" className='title'>
                {isch ? <span>关于我们</span> : null}
                <span>About us</span>
              </a>
            </div>
            {isch ? <div>
              <a href="/about-us/company">
                <span>
                  <img src={require('../../../assets/images/top-menu/menu_jian.png')} alt="" />
                  公司简介
              </span>
                {/* <span className='maginLeft'>About us</span> */}
              </a>
            </div> : null}
            {/* <div>
              <a href="/about-us/team-member">
                {isch ? <span>
                  <img src={require('../../../assets/images/top-menu/menu_jing.png')} alt="" />
                  精英团队
              </span > : null}
                <span className='maginLeft'>Team member</span>
              </a>
            </div>
            <div>
              <a href="/about-us/contact-us">
                {isch ? <span>
                  <img src={require('../../../assets/images/top-menu/menu_lian.png')} alt="" />
                  联系我们
              </span> : null}
                <span className='maginLeft'>Contact us</span>
              </a>
            </div> */}
          </div>
        }


      </div>

    )
  }
}

function mapStateToProps(state) {
  return {
    isch: state.isch
  }
}

export default connect(mapStateToProps, { ...SagaAction })(TopMenu)
